<section id="footer" class="pt-5">
  <div class="container">
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex flex-column footer-logo">
        <img src="../../../assets/img/med-medtoday-logo.svg" alt="medtoday" width="131px">
        <span class="copyright mt-3">© 2021 medtoday</span>
      </div>

      <div class="col-xl-2 col-lg-2 d-flex flex-column">
        <span class="footer-header">Rechtliches</span>
        <span class="mt-3">
          <a class="text-white-link" routerLink="/impressum">Impressum</a>
        </span>
        <span class="mt-2">
          <a class="text-white-link" routerLink="/datenschutz">Datenschutz</a>
        </span>
        <span class="mt-2">
          <a class="text-white-link" routerLink="/disclaimer">Disclaimer</a>
        </span>
        <span class="mt-2">
          <a class="text-white-link" routerLink="/cookies">Cookies</a>
        </span>
      </div>
      <div class="col-xl-auto col-lg-auto d-flex flex-column">
        <span class="footer-header">Kontaktdaten</span>
        <span class="text-white-link mt-3">medtoday</span>
        <span class="text-white-link">Maria-Hilf-Straße 15</span>
        <span class="text-white-link">50667 Köln</span>
        <span class="text-white-link mb-5">info@medtoday.de</span>
      </div>
    </div>
  </div>
</section>