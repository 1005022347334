<section id="home">
  <div class="container">
    <div class="header">
      <div class="row">
        <div class="col-12">
          <h2>In Kürze finden Sie hier weitere Informationen zu folgenden Projekten:</h2>
        </div>
      </div>
    </div>
    <div class="background-img">
      <img class="background-img-size" src="../../../assets/img/med-background.png" alt="background">
    </div>

    <div class="row congress-wrapper">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 flex-column">
        <div class="logo-img-box">
          <img class="img-size" src="../../../assets/img/med-asco-direct.svg" alt="asco-direct">
        </div>
        <div class="paragraph">
          <span>
            ASCO Direct™ präsentiert die wichtigsten Studien zu 16 onkologischen Schwerpunktthemen vom ASCO Annual Meeting 2021. 
            Verfolgen Sie die Übertragung live vom 06. bis 08. Juni 2021 auf <a class="url-link" href="www.medtoday.de" target="_blank">www.medtoday.de</a>. 
            Alle Inhalte samt der Präsentationen als PDF-Download stehen Ihnen im Anschluss an die Live-Übertragung kostenlos und rund um die Uhr on Demand zur Verfügung.
          </span>
        </div>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 flex-column">
        <div class="logo-img-box">
          <img class="img-size" src="../../../assets/img/med-hematology-today-logo.svg" alt="hematology-today">
        </div>
        <div class="paragraph">
          <span>
            Hematology Today berichtet vom diesjährigen EHA2021 Virtual Congress. 
            Die digitale Kongressberichterstattung findet vom 11. bis 13. Juni 2021 hier auf <a class="url-link" href="www.medtoday.de" target="_blank">www.medtoday.de</a> statt. 
            Unsere 27 Experten analysieren und präsentieren Ihnen in Highlight-Sessions 
            und Diskussionsrunden die neusten hämatologischen Daten mit besonderer Relevanz für die Versorgungssituation in Deutschland. 
          </span>
        </div>
      </div>
    </div>
  </div>
</section>