import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CookiesComponent } from './views/cookies/cookies.component';
import { DisclaimerComponent } from './views/disclaimer/disclaimer.component';
import { HomeComponent } from './views/home/home.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { PrivacyComponent } from './views/privacy/privacy.component';


export const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'impressum', component: ImprintComponent },
  { path: 'datenschutz', component: PrivacyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
